import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "./CookieManager.css";
import "./CookieManager-tablet.css";
import "./CookieManager-mobile.css";

const CookieManager: React.FC = () => {
  const [isSettings, setIsSettings] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("ne");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [isVisible, setIsVisible] = useState<boolean>(() => {
    // Initialize to true if no consent cookie is present
    return Cookies.get("user-consent") === undefined;
  });

  /* Preveri sirino ekrana, da prilagodi visino piskotkov na telefonu, ce je soglasje ali nastavitve */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function addGAScript() {
    // Preveri, ce ze obstaja ga-script
    if (!document.getElementById("ga-script")) {
      // Kreira async skripto
      var gaScript = document.createElement("script");
      gaScript.id = "ga-script";
      gaScript.async = true;
      gaScript.src = "https://www.googletagmanager.com/gtag/js?id=G-Y9NMXY5X4P";

      // Dodamo skripto med glavo in telesom
      document.head.insertAdjacentElement("afterend", gaScript);

      // Kreiramo inline skriptoza dataLayer in gtag inicializacijo
      var inlineScript = document.createElement("script");
      inlineScript.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Y9NMXY5X4P');
            `;

      // Dodamo skripto med glavo in telesom
      document.head.insertAdjacentElement("afterend", inlineScript);
    }
  }

  /* Preveri piskotke ob vstopu v spletno stran */
  useEffect(() => {
    // Preverimo, ce je uporabnik privolil v piskotke
    if (Cookies.get("user-consent") === "da") {
      addGAScript();
    }
  }, []);

  const handleAcceptAll = () => {
    Cookies.set("user-consent", "da", { expires: 365 });
    setIsVisible(false);
    addGAScript();
  };

  const handleSettings = () => {
    setIsSettings(true);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
  };

  const handleSaveSettings = () => {
    Cookies.set("user-consent", selectedOption, { expires: 365 });
    setIsSettings(false);
    setIsVisible(false);

    // Preverimo, ce je uporabnik privolil v piskotke
    if (selectedOption === "da") {
      addGAScript();
    }
  };

  const getHeight = () => {
    if (!isSettings && isMobile) {
      return "45vw";
    } else if (isSettings && isMobile) {
      return "80vw";
    }
    return undefined;
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-manager" style={{ height: getHeight() }}>
      {isSettings ? (
        <div className="settings-view">
          <div className="settings-mobile">
            <div className="settings-left">
              <div className="settings-functional">
                <h2>Funkcionalni:</h2>
                <p>Nujno potrebni za delovanje spletne strani.</p>
              </div>
              <div className="settings-statistics">
                <h2>Statistika:</h2>
                <p>
                  Uporabljajo se izključno za beleženje obiskov spletne strani
                  in socialnih omrežij. Ne zbirajo informacij, preko katerih bi
                  lahko identificirali uporabnika.
                </p>
              </div>
            </div>
            <div className="settings-middle">
              <div id="button-da">DA</div>

              <div className="option-button">
                <div
                  className={`option ${selectedOption === "ne" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("ne")}
                >
                  NE
                </div>
                <div
                  className={`option ${selectedOption === "da" ? "selected" : ""}`}
                  onClick={() => handleOptionClick("da")}
                >
                  DA
                </div>
              </div>
            </div>
          </div>
          <div className="settings-right">
            <button
              className="button"
              id="button-izberi-vse-nastavitve"
              onClick={handleAcceptAll}
            >
              IZBERI VSE IN ZAPRI
            </button>
            <button
              className="button"
              id="button-shrani-nastavitve"
              onClick={handleSaveSettings}
            >
              SHRANI NASTAVITVE
            </button>
          </div>
        </div>
      ) : (
        <div className="consent-view">
          <div className="consent-left">
            <h2>Soglasje za piškotke</h2>
            <p>
              Spletna stran uporablja in shranjuje piškotke (angl. Cookies).{" "}
              <br />
              Piškotke uporabljamo za analizo prometa spletne strani in
              socialnih omrežij. <br />
              Piškotkov ne uporabljamo za komercialne namene trženja. <br />
              S klikom na gumb „IZBERI VSE IN ZAPRI“ soglašate z uporabo
              piškotkov.
              <br />{" "}
            </p>
          </div>
          <div className="consent-right">
            <button
              className="button"
              id="button-izberi-vse"
              onClick={handleAcceptAll}
            >
              IZBERI VSE IN ZAPRI
            </button>
            <button
              className="button"
              id="button-nastavitve"
              onClick={handleSettings}
            >
              SPREMENI NASTAVITVE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieManager;
